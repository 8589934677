const russian = {
  //navbar section
  navbarTitle1: "Главная",
  navbarTitle2: "Услуги",
  navbarTitle3: "Цены",
  navbarTitle4: "Наши клиенты",
  navbarTitle5: "О нас",
  navbarTitle6: "Контакты",
  // home page 
  homeHeaderTitle1: "Запускаем рекламу, которая работает! Максимальная видимость ",
  homeHeaderTitle2: "Мы используем ресурсы, чтобы создать лучшие решения.",
  homeHeaderSubtitle2: " и рост продаж с",
  homeHeaderFormTitle: "Свяжитесь с нами",
  homeHeaderInputTitle: "Имя",

  homeHeaderInputTitle1: "Номер телефона",
  homeHeaderInputTitle2: "Электронной почты",
  homeHeaderInputTitle3: "Сервис",
  homeHeaderInputTitle4: "Компания",
  homeHeaderInputTitle5: "Ваш рекламный бюджет",


  homeButton: "Начать",
  homeFormAlertTitle1: "Пожалуйста, укажите ваше имя.",
  homeFormAlertTitle2: "Пожалуйста, укажите ваш номер телефона.",
  homeFormAlertTitle3:"Пожалуйста, введите имя, содержащее как минимум 4 символа.",
  homeFormAlertTitle4:"Пожалуйста, укажите номер телефона, содержащий не менее 12 символов.",
  homeFormAlertTitle12: "Пожалуйста, укажите ваше Компания.",
  homeFormAlertTitle13:"Пожалуйста, введите Компания, содержащее как минимум 4 символа.",
  // our service
  ourServiceTitle: "Наши услуги",
  ourServiceSubtitle: "Adigmo предлагает широкий спектр услуг для эффективной интернет-рекламы. Исходя из ваших бизнес-целей, продукта, УТП, бюджета, сроков и выбора, мы составим для вас план и подберем эффективные каналы продвижения.",
  ourServiceCardText1: "Google — крупнейшая рекламная платформа в мире. Любой человек обращается сюда перед совершением покупки. Поэтому ваш бизнес должен рекламироваться здесь. Наша команда создаст и оптимизирует эффективность ваших рекламных кампаний. Доверьтесь 17-летнему опыту!",
  ourServiceCardText2: "Facebook, Instagram или же таргетированная реклама - является одной из самых эффективных типов рекламы интернет маркетинга. Визуальная составная часть данных типов рекламы вовлекает и приводит к высоким уровням конверсии. Adigmo обеспечит вам узнаваемость и рост продаж. Выбирайте профессионалов!",
  ourServiceCardText3: "Yandex - одна из крупнейших платформ в СНГ, которая год за годом преувеличивает свою аудиторию. Многие бизнесы рано или поздно будут там рекламироваться, так станьте  одним из первых. Мы настроем вашу рекламу, оптимизируем и отслеживаем вашу рекламную кампанию постоянно. Увеличивайте каналы рекламы!",
  ourServiceCardText4: "Search Engine Optimization -  органическое продвижение сайта. Данный тип продвижения предоставляет возможность и без рекламы выходить в топы поисковых запросов. Наши SEO-специалисты оптимизируют ваш веб-сайт для более высокого рейтинга в поисковых системах. Новые клиенты ждут вас!",
  // price service page
priceTitle:"Цены на наши услуги",
priceSubtitle:"Для вашего удобства мы создали три уникальных тарифных планов. Каждый тарифный план включает в себе широкий спектр услуг интернет рекламы, предоставляемый специалистами Adigmo. Начните рекламироваться уже сегодня!",
priceCardtitle1:"Стандартный",
priceCardtitle2:"Премиум",
priceCardtitle3:"Предприятие",
priceCardtext1:"Бесплатный аудит аккаунта",
priceCardtext2:"от 6 000 000 сум ежемесячного рекламного бюджета",
priceCardtext22:"от 12 000 000 сум ежемесячного рекламного бюджета",
priceCardtext3:"Бесплатная настройка аккаунта Google Ads (если его нет)",
priceCardtext4:"Подробный еженедельный отчет",
priceCardtext5:"Подробный ежемесячный отчет",
priceCardtext6:"Поиск: 50 ключевых слов, 1 группа объявлений, 1 объявление",
priceCardtext7:"Дисплей: 1 дизайн баннера",
priceCardtext8:"Оптимизация кампании",
priceCardtext9:"Бесплатное исследование ключевых слов",
priceCardtext10:"Ваши кампании будут управляться экспертам",
// second card details
priceCard2text1:"Бесплатный аудит аккаунта",
priceCard2text2:"Бесплатная настройка аккаунта Google Ads (если его нет)",
priceCard2text3:"Подробный еженедельный отчет",
priceCard2text4:"Подробный ежемесячный отчет",
priceCard2text5:"Настройка 5 кампаний",
priceCard2text6:"Поиск: 500 ключевых слов, 5 групп объявлений, 5 объявлений",
priceCard2text7:"Дисплей: 1 дизайн баннера",
priceCard2text8:"Performance Max кампания",
priceCard2text9:"A/B Testing",
priceCard2text10:"Оптимизация кампаний",
priceCard2text11:"Бесплатное исследование ключевых слов",
priceCard2text12:"Ваши кампании будут управляться экспертами",
// third card details
priceCard3Subtitle1:"Договорная",
priceCard3Subtitle2:"Решения, адаптированные под ваши потребности",
priceCard3text1:"Бесплатный аудит аккаунта",
priceCard3text2:"Бесплатная настройка аккаунта Google Ads (если его нет)",
priceCard3text3:"Отчеты",
priceCard3text4:"Настройка 10 кампаний (или больше) компаний",
priceCard3text5:"Поиск: 1000+ ключевых слов, 5+ групп объявлений, 5+ объявлений",
priceCard3text6:"Дисплей: 5+ дизайн баннера Performance Max кампания 5+ Группы объектов",
priceCard3text7:"Оптимизация кампаний и сайта",
priceCard3text8:"Настройка отслеживания конверсий для Google Ads",
priceCard3text9:"A/B Testing",
priceCard3text10:"Установка CRM системы",
priceCard3text11:"Ремаркетинг для поисковых кампаний",
priceCard3text12:"Поддержка клиентов в Телеграм",

// clients page
clientTitle:"Наши клиенты",
clientSubtitle:"Ознакомьтесь наши клиентами, с которыми мы сотрудничали и сотрудничаем по сей день. Станьте следующим!",
clientCardText0:"Агро Финанс Лизинг - ведущая компания в Узбекистане, специализирующаяся на лизинге электротранспорта.",
clientCardText1:"Chef Pomodoro - мировая компания, специализирующаяся на продаже продуктов для приготовления пиццы.",
clientCardText2:"EXANTE - компания в сфере финансовых технологий, предоставляющая централизованные инвестиционные решения и инфраструктуру для финансовых учреждений.",
clientCardText3:"ALLHDD.COM - компания с базой в Нью-Йорке, специализирующаяся на решениях для серверов и сетей, разработанных специально для корпоративных центров обработки данных и сетей.",
clientCardText4:"AvaTrade - пионер онлайн-торговли с 2006 года. Компания была создана командой финансовых специалистов и экспертов по электронной коммерции для обеспечения оптимальных условий торговли для индивидуальных трейдеров.",
clientCardText5:"Cedrus Residence - компания, специализирующаяся на роскошных апартаментах в Швейцарии, расположенных в живописном регионе Тичино между берегами озера и зелеными долинами Альп.",
clientCardText6:"Saxo Bank - мировой инвестиционный банк, в которого доверяют более 1 000 000 клиентов по всему миру.",
clientCardText7:"UNHCR - УВКБ ООН - международная организация, посвященная спасению жизней беженцев.",
clientCardText8:"Trustpilot был основан в 2007 году с целью создания независимой валюты доверия. Это цифровая платформа, которая соединяет бизнес и потребителей, строя доверие и вдохновляя на сотрудничество.",
buttonMore:"Загрузить Еще",
// About Us page
aboutUsTitle:"О нас",
aboutUsSubtitle:"",
aboutUSTextBoxTitle:"О нашем агентстве",
aboutUSTextBoxText1:'Добро пожаловать в раздел "О нас"! Мы - специализированное цифровое маркетинговое агентство, стремящееся помочь вашему бизнесу процветать в онлайн-пространстве. С более чем 17-летним опытом наша команда опытных маркетологов успешно реализовала более 1 000 проектов в различных нишах и странах.',
aboutUSTextBoxText2:"Наша миссия - усилить ваш бренд, повысить ваше онлайн-присутствие и достичь конкретных результатов. Мы гордимся нашими индивидуальными стратегиями и инновационными подходами, чтобы обеспечить ваш успех. Как ваши надежные партнеры, мы тесно сотрудничаем с вами, чтобы понять ваши цели и достичь исключительных результатов.",
aboutUSTextBoxText3:"Мы разрабатываем индивидуальные стратегии и инновационные подходы  каждому клиенту, чтобы обеспечить ваш успех.",
aboutUsStatisticText1:"Довольных Клиентов",
aboutUsStatisticText2:"Лет Опыта",
aboutUsStatisticText3:"Зарубежных Проектов",
// contact page
contactTitle:"Контакты",
contactSubtitle:"Если у вас остались вопросы или же хотите получить специальное предложение то оставьте свой номер и напишите свое имя.",
fullName:"Полное Имя",
buttonSend:"Отправить",

// footer section words
footerTitle1:"Компания",
footerTitle2:" Маркетинг",
footerTitle3:"Местоположение",
footerTitle4:"Подписаться",
footerColText1:"Главная",
footerColText2:"Наши Услуги",
footerColText3:"Наши Цены",
footerColText4:"Все Проекты",
footerCol2Text:"",
footerCol2Text1:"FAQ",
footerCol2Text2:"Выполнить Маркетинг",
footerCol2Text3:"Платежная Система",
footerCol2Text4:"Статус Проекта ",
footerCol2Text5:"Варианты Оплаты",
footerCol3Text1:"Узбекистан, Тошкент",
footerCol3Text2:"Чиланзорский Район, Зухур",
footerCol3Text3:"Палвон, 3-Й Дом",
footerCol3Text4:"телефон: +998 (97) 753 14 01",
// Success Page
succesPageTitle:"Здравствуйте, дорогой друг Адигмо!",
successPageText1:"Ура! Ваша форма контакта успешно отправлена! Наша команда с нетерпением ждет возможности связаться с вами.",
successPageText2:"Ожидайте звонка или письма от наших специалистов в ближайшее время. Вместе мы воплотим ваши идеи в реальность и создадим успешное партнерство.",
successPageText4:"Благодарим вас за обращение в Адигмо и до скорого общения!",
successPageText5:"С уважением, Команда Адигмо",
buttonWell:"хорошо"

};

export default russian;
